var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "p-2 h-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-text', [_c('b-row', [_c('b-col', {
    staticClass: "text-left"
  }, [_vm._v(" Members ")])], 1), _c('b-row', [_c('vue-perfect-scrollbar', {
    staticClass: "scroll-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "ul"
    }
  }, _vm._l(_vm.members, function (member) {
    return _c('li', {
      key: member.id,
      staticClass: "d-flex flex-row justify-content-center"
    }, [_c('b-col', {
      staticClass: "mt-1 text-left"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-primary "
      }
    }, [_vm._v(" " + _vm._s(member.user.name) + " ")])], 1), _c('b-col', {
      staticClass: "mt-1 text-left"
    }, [_vm._v(" " + _vm._s(member.role.title) + " ")])], 1);
  }), 0)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }