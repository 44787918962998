<template>
  <b-card
    :title="tournament.name"
    class="text-center h-100"
    no-body
  >
    <b-card-text class="p-2 flex-grow-1">
      <!-- Game -->
      <b-row>
        <b-col><h3 class="text-truncate">
          {{ tournament.name }}
        </h3></b-col>
      </b-row>

      <!-- Game -->
      <b-row>
        <b-col>Game: {{ tournament.discipline.title }}</b-col>
      </b-row>

      <!-- Teams -->
      <b-row>
        <b-col>
          {{ participantsJoined }} of {{ tournament.participants_number }}
          {{ participantType }} joined
        </b-col>
      </b-row>

      <!-- Start -->
      <b-row>
        <b-col>Start: {{ startAt }}</b-col>
      </b-row>

      <!-- Days to join -->
      <b-row v-if="canRegister">
        <b-col>
          {{ timeTillDate(tournament.registration_close_at) }} left to join
        </b-col>
      </b-row>

      <!-- Match type -->
      <b-row>
        <b-col />
      </b-row>

      <!-- Platform -->
      <b-row>
        <b-col>
          <span>Platform:</span>
          <span
            v-for="platform in tournament.platforms"
            :key="platform.id"
          >
            {{ platform.title }}
          </span>
        </b-col>
      </b-row>

      <!-- Status -->
      <b-row class="mt-1">
        <b-col>
          <b-badge
            v-if="showRegisterButton"
            class="cursor-pointer"
            variant="light-success"
            @click="handleSignupClick"
          >
            Signup phase - join now
          </b-badge>

          <b-badge
            v-if="showFinishedButton"
            class="cursor-pointer"
            variant="light-primary"
            @click="handleTournamentOverviewClick"
          >
            Ended - more info
          </b-badge>

          <b-badge
            v-if="showLiveButton"
            class="cursor-pointer"
            variant="light-warning"
            @click="handleTournamentOverviewClick"
          >
            Live now - more info
          </b-badge>
        </b-col>
      </b-row>
    </b-card-text>

    <b-card-footer>
      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-avatar
            :src="getDisciplineImage(tournament?.discipline?.id)"
            square
            size="44"
          />
        </b-col>
        <b-col class="d-flex justify-content-start">
          <b-row class="flex-column">
            <!--            <b-col>-->
            <!--              <div><b>Prizes:</b></div>-->
            <!--              <div>{{ tournament?.prizes || ' TBA ' }}</div>-->
            <!--            </b-col>-->

            <span v-if="!tournament?.entry_fee">
              <b-badge
                variant="light-success"
              >
                NO ENTRY FREE
              </b-badge>
            </span>
          </b-row>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BCard,
  BCardFooter,
  BCardText,
  BCol,
  BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import intervalToDuration from 'date-fns/intervalToDuration'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import TournamentStatusType from '@/constants/TournamentStatusType'
import { utcToTimezone, utcToTimezoneFormat } from '@/common/date-utils'
import { getDisciplineImage } from '@/views/common'
import { isBefore } from 'date-fns'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardFooter,
    BAvatar,
    BBadge,
  },
  props: {
    tournament: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    canRegister() {
      if (!this.tournament?.enable_registration) {
        return false
      }

      return isBefore(new Date(
        this.tournament.registration_close_at,
      ), new Date())
    },
    entryFee() {
      return !this.tournament.entry_fee ? 'No entry fee' : `Entry fee:  ${this.tournament.entry_fee} €`
    },
    participantType() {
      if (
          this.tournament?.participant_type_id === TournamentParticipantType.player
      ) {
        return 'players'
      }
      return 'teams'
    },
    participantsJoined() {
      let joinedParticipants = 0

      if (this.tournament?.participants) {
        joinedParticipants = this.tournament?.participants.length
      }

      return joinedParticipants
    },
    startAt() {
      return utcToTimezoneFormat(
        this.tournament.start_at,
        this.tournament.timezone_id,
      )
    },
    showLiveButton() {
      return this.tournament?.status_id === TournamentStatusType.IN_PROGRESS
    },
    showRegisterButton() {
      return this.tournament?.status_id === TournamentStatusType.PUBLISHED
    },
    showFinishedButton() {
      return this.tournament?.status_id === TournamentStatusType.FINISHED || this.tournament?.status_id === TournamentStatusType.CLOSED
    },
  },
  methods: {
    getDisciplineImage,
    handleTournamentOverviewClick() {
      // ToDo: handle redirect to tournament page
    },
    handleSignupClick() {
      // ToDo: handle tournament signup
    },
    timeTillDate(date) {
      if (!date) {
        return null
      }

      const { days, hours, minutes } = intervalToDuration({
        start: utcToTimezone(date, this.tournament.timezone_id),
        end: new Date(),
      })

      const daysText = days > 0 ? `${days} days ` : ''
      const hoursText = days === 0 && hours > 0 ? `${hours} hours ` : ''
      const minutesText = days === 0 && hours === 0 && minutes > 0 ? `${minutes} minutes ` : ''

      return `${daysText}${hoursText}${minutesText}`
    },
  },
}
</script>

<style lang="scss">
  .card {
    color: #d0d2d6;
  }

</style>
