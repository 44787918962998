var render = function () {
  var _vm$organizer, _vm$organizer2, _vm$organizer2$name, _vm$organizer3, _vm$organizer4, _vm$organizer5, _vm$organizer6, _vm$organizer7, _vm$organizer7$countr, _vm$organizer7$countr2, _vm$organizer8, _vm$organizer8$countr, _vm$organizer9, _vm$organizer10, _vm$organizer11, _vm$organizer12, _vm$organizer12$tourn, _vm$organizer13, _vm$organizer14, _vm$organizer15;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "lg": "9",
      "md": "12"
    }
  }, [_c('b-card', {
    staticClass: "p-2 h-100",
    attrs: {
      "no-body": ""
    }
  }, [_vm.loading ? _c('b-row', {
    staticClass: "text-center"
  }, [_c('b-col', [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1) : _vm._e(), _vm.organizer ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "7",
      "sm": "12"
    }
  }, [_c('b-row', {
    staticClass: "flex-nowrap"
  }, [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$organizer = _vm.organizer) === null || _vm$organizer === void 0 ? void 0 : _vm$organizer.avatar_image,
      "text": (_vm$organizer2 = _vm.organizer) === null || _vm$organizer2 === void 0 ? void 0 : (_vm$organizer2$name = _vm$organizer2.name) === null || _vm$organizer2$name === void 0 ? void 0 : _vm$organizer2$name.substring(0, 2),
      "rounded": "",
      "size": "120px",
      "variant": "light-primary"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('b-row', {
    staticClass: "flex-column"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-start"
  }, [(_vm$organizer3 = _vm.organizer) !== null && _vm$organizer3 !== void 0 && _vm$organizer3.is_verified ? _c('feather-icon', {
    staticClass: "mr-1",
    staticStyle: {
      "color": "#27AE60"
    },
    attrs: {
      "size": "25",
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _c('h3', {
    staticClass: "mb-0 mr-1"
  }, [_vm._v(" " + _vm._s((_vm$organizer4 = _vm.organizer) === null || _vm$organizer4 === void 0 ? void 0 : _vm$organizer4.name) + " ")])], 1), _c('b-col', [_vm._v(" " + _vm._s((_vm$organizer5 = _vm.organizer) === null || _vm$organizer5 === void 0 ? void 0 : _vm$organizer5.email) + " ")]), _c('b-col', {
    staticClass: "d-flex align-items-center"
  }, [(_vm$organizer6 = _vm.organizer) !== null && _vm$organizer6 !== void 0 && _vm$organizer6.country ? _c('span', {}, [_c('country-flag', {
    attrs: {
      "country": (_vm$organizer7 = _vm.organizer) === null || _vm$organizer7 === void 0 ? void 0 : (_vm$organizer7$countr = _vm$organizer7.country) === null || _vm$organizer7$countr === void 0 ? void 0 : (_vm$organizer7$countr2 = _vm$organizer7$countr.code) === null || _vm$organizer7$countr2 === void 0 ? void 0 : _vm$organizer7$countr2.toLowerCase(),
      "size": "normal"
    }
  })], 1) : _vm._e(), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s((_vm$organizer8 = _vm.organizer) === null || _vm$organizer8 === void 0 ? void 0 : (_vm$organizer8$countr = _vm$organizer8.country) === null || _vm$organizer8$countr === void 0 ? void 0 : _vm$organizer8$countr.name) + " ")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-1 mr-1"
  }, [_c('social-links-list', {
    attrs: {
      "links": (_vm$organizer9 = _vm.organizer) === null || _vm$organizer9 === void 0 ? void 0 : _vm$organizer9.links
    }
  })], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "flex-nowrap mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4"
    }
  }, [_c('b-badge', {
    staticClass: "p-1 font-medium-2 w-100",
    staticStyle: {
      "max-width": "120px"
    },
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(((_vm$organizer10 = _vm.organizer) === null || _vm$organizer10 === void 0 ? void 0 : _vm$organizer10.balance) || 0) + " € ")])], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('b-row', {
    staticClass: "flex-column"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-button', {
    staticClass: "mb-1 font-small-1 w-100",
    attrs: {
      "disabled": "disabled",
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Send funds ")])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-button', {
    staticClass: "mb-1 font-small-1  w-100",
    attrs: {
      "to": {
        name: 'apps-users-edit',
        params: {
          id: (_vm$organizer11 = _vm.organizer) === null || _vm$organizer11 === void 0 ? void 0 : _vm$organizer11.id
        }
      },
      "disabled": "disabled",
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Withdraw funds ")])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "5",
      "sm": "12"
    }
  }, [_c('h4', [_vm._v("Our latest tournaments:")]), _c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l((_vm$organizer12 = _vm.organizer) === null || _vm$organizer12 === void 0 ? void 0 : (_vm$organizer12$tourn = _vm$organizer12.tournaments) === null || _vm$organizer12$tourn === void 0 ? void 0 : _vm$organizer12$tourn.filter(function (t) {
    return t.status_id === _vm.TournamentStatusType.PUBLISHED || t.status_id === _vm.TournamentStatusType.IN_PROGRESS;
  }), function (tournament) {
    return _c('li', {
      key: tournament.id
    }, [_c('b-row', {
      staticClass: "flex-nowrap"
    }, [_c('b-col', {
      staticClass: "pr-0"
    }, [_c('feather-icon', {
      attrs: {
        "icon": "CircleIcon"
      }
    })], 1), _c('b-col', {
      attrs: {
        "sm": "7"
      }
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'tournament-overview',
          params: {
            id: tournament.id
          }
        }
      }
    }, [_c('h5', [_vm._v(_vm._s(tournament.name))])])], 1), _c('b-col', {
      attrs: {
        "sm": "4"
      }
    }, [_vm._v(" " + _vm._s(_vm.tournamentStatus(tournament)) + " ")])], 1)], 1);
  }), 0)])], 1) : _vm._e()], 1)], 1), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "lg": "3",
      "md": "12"
    }
  }, [_c('organizer-members-card', {
    attrs: {
      "members": (_vm$organizer13 = _vm.organizer) === null || _vm$organizer13 === void 0 ? void 0 : _vm$organizer13.members
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "options": _vm.filterOptions,
      "switches": ""
    },
    on: {
      "change": _vm.handleFilterChange
    },
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  })], 1)], 1)], 1), _vm.resultsLoading ? _c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1)], 1) : _vm._e(), !_vm.resultsLoading && (!((_vm$organizer14 = _vm.organizer) !== null && _vm$organizer14 !== void 0 && _vm$organizer14.tournaments) || ((_vm$organizer15 = _vm.organizer) === null || _vm$organizer15 === void 0 ? void 0 : _vm$organizer15.tournaments.length) === 0) ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('feather-icon', {
    staticClass: "m-2",
    attrs: {
      "icon": "FrownIcon",
      "size": "50"
    }
  }), _vm._v(" There are no competitions ")], 1)], 1) : _vm._e(), !_vm.resultsLoading && _vm.tournaments && _vm.tournaments.length > 0 ? _c('b-row', _vm._l(_vm.tournaments, function (tournament) {
    var _tournament$disciplin;
    return _c('b-col', {
      key: tournament === null || tournament === void 0 ? void 0 : tournament.id,
      staticClass: "mt-1",
      attrs: {
        "md": "6",
        "lg": "4",
        "sm": "12"
      }
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'tournament-info',
          params: {
            id: tournament.id,
            game: (_tournament$disciplin = tournament.discipline) === null || _tournament$disciplin === void 0 ? void 0 : _tournament$disciplin.slug
          }
        }
      }
    }, [_c('tournament-search-result-card', {
      attrs: {
        "tournament": tournament
      }
    })], 1)], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }