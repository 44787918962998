<template>

  <b-card
    no-body
    class="p-2 h-100"
  >
    <b-card-text>
      <b-row>
        <b-col class="text-left">
          Members
        </b-col>
      </b-row>
      <b-row>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scroll-list scroll-area"
          tagname="ul"
        >
          <li
            v-for="member in members"
            :key="member.id"
            class="d-flex flex-row justify-content-center"
          >
            <b-col class="mt-1 text-left">
              <b-badge
                variant="light-primary "
              >
                {{ member.user.name }}
              </b-badge>
            </b-col>
            <b-col class="mt-1 text-left">
              {{ member.role.title }}
            </b-col>
          </li>
        </vue-perfect-scrollbar>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BBadge,
    BCard,
    BCardText,
    BCol,
    BRow,
    VuePerfectScrollbar,
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {},
}
</script>

<style lang="scss">
.scroll-list {
  padding: 0;
  height: auto;
  top: 100%;
  left: 0;
  width: 100%;
}

.badge {
  min-width: 100px;
  padding: 10px;
}
</style>
