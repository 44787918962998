var render = function () {
  var _vm$tournament, _vm$tournament$discip, _vm$tournament2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "text-center h-100",
    attrs: {
      "title": _vm.tournament.name,
      "no-body": ""
    }
  }, [_c('b-card-text', {
    staticClass: "p-2 flex-grow-1"
  }, [_c('b-row', [_c('b-col', [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.tournament.name) + " ")])])], 1), _c('b-row', [_c('b-col', [_vm._v("Game: " + _vm._s(_vm.tournament.discipline.title))])], 1), _c('b-row', [_c('b-col', [_vm._v(" " + _vm._s(_vm.participantsJoined) + " of " + _vm._s(_vm.tournament.participants_number) + " " + _vm._s(_vm.participantType) + " joined ")])], 1), _c('b-row', [_c('b-col', [_vm._v("Start: " + _vm._s(_vm.startAt))])], 1), _vm.canRegister ? _c('b-row', [_c('b-col', [_vm._v(" " + _vm._s(_vm.timeTillDate(_vm.tournament.registration_close_at)) + " left to join ")])], 1) : _vm._e(), _c('b-row', [_c('b-col')], 1), _c('b-row', [_c('b-col', [_c('span', [_vm._v("Platform:")]), _vm._l(_vm.tournament.platforms, function (platform) {
    return _c('span', {
      key: platform.id
    }, [_vm._v(" " + _vm._s(platform.title) + " ")]);
  })], 2)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_vm.showRegisterButton ? _c('b-badge', {
    staticClass: "cursor-pointer",
    attrs: {
      "variant": "light-success"
    },
    on: {
      "click": _vm.handleSignupClick
    }
  }, [_vm._v(" Signup phase - join now ")]) : _vm._e(), _vm.showFinishedButton ? _c('b-badge', {
    staticClass: "cursor-pointer",
    attrs: {
      "variant": "light-primary"
    },
    on: {
      "click": _vm.handleTournamentOverviewClick
    }
  }, [_vm._v(" Ended - more info ")]) : _vm._e(), _vm.showLiveButton ? _c('b-badge', {
    staticClass: "cursor-pointer",
    attrs: {
      "variant": "light-warning"
    },
    on: {
      "click": _vm.handleTournamentOverviewClick
    }
  }, [_vm._v(" Live now - more info ")]) : _vm._e()], 1)], 1)], 1), _c('b-card-footer', [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.getDisciplineImage((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : (_vm$tournament$discip = _vm$tournament.discipline) === null || _vm$tournament$discip === void 0 ? void 0 : _vm$tournament$discip.id),
      "square": "",
      "size": "44"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-start"
  }, [_c('b-row', {
    staticClass: "flex-column"
  }, [!((_vm$tournament2 = _vm.tournament) !== null && _vm$tournament2 !== void 0 && _vm$tournament2.entry_fee) ? _c('span', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" NO ENTRY FREE ")])], 1) : _vm._e()])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }